<template>
  <div class="home">
    <div v-if="!err" class="main">
      <div class="balance df aic">
        <div class="header">
          <div class="userinfo">
            <img :src="userInfo.head" alt="">
            <div class="mess">
              <span>{{ userInfo.name }}</span>
              <span>{{ userInfo.id }}</span>
            </div>
          </div>
        </div>
        <div class="df fdc aic moneybox">
          <p>嘿糖余额</p>
          <div class="df aic">
            <img src="@/assets/tang.png" alt="" height="35px">
            <p class="balancemoney">{{ userInfo.heiTang || 0 }}</p>
          </div>
        </div>
      </div>
      <ul class="paylist">
        <li v-for="(item, index) in payList" :key="index" :class="chooseindex == index ? 'on' : ''" @click="choose(index, item)">
          <div class="df jcsb aic">
            <div class="df left aic">
              <img src="@/assets/tang.png" alt="" height="35px">
              <div class="df fdc aifs center jcsb">
                <p>{{ item.heiTang }}嘿糖</p>
                <span>已包含赠送的{{ item.giveHeiTang }}个嘿糖</span>
              </div>
            </div>
            <span class="rightmoney">
              ￥{{ item.price }}
            </span>
          </div>
          <div v-if="item.type == 1" class="sc">首充福利</div>
        </li>
      </ul>
      <button class="paybtn" @click="getorder()">立即充值</button>
    </div>
    <div v-else-if="err" class="errmess">
      <img src="../assets/180.jpg" alt="">
      <div class="df aic inputbox">
        <div><input type="text" v-model="header.phone" placeholder="请输入手机号"></div>
        <!-- <van-field v-model="header.phone" :border="true" label="手机号" placeholder="请输入手机号" /> -->
        <button class="phonebutton" @click="render()">充值</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      payList: [],
      chooseindex: 0,
      choosedata: {},
      show: false,
      checkwx: false,
      checkzfb: false,
      alidata: '',
      href:'',
      userInfo: {},
      header: {
        phone: ''
      },
      errmess: '',
      err: true
    }
  },
  mounted() {
    // this.href = location.href
    // this.href = this.href.substring(this.href.indexOf('?')+1)
    // this.header.phone = this.getroute(this.href, 'phone')
    // this.render()
  },
  methods: {
    render() {
      // 获取套餐和用户信息
      axios.get('https://api.mihuan365.com/app/pay/getHeiTangListByPhone?phone='+this.header.phone).then(res => {
        if (res.data.data) {
          this.payList = res.data.data.heiTangList
          this.choosedata = this.payList[0]
          this.userInfo.head = res.data.data.head
          this.userInfo.name = res.data.data.name
          this.userInfo.id = res.data.data.id
          this.userInfo.heiTang = res.data.data.heiTang
          this.err = false
        } else {
          alert(res.data.msg)
          this.err = true
        }
      })
    },
    choose(index, item) {
      this.chooseindex = index
      this.choosedata = item
    },
    getorder() {
      axios.post('https://api.mihuan365.com/app/pay/generateScheme', {
            phone: this.header.phone,
            heiTangMealId: this.choosedata.id
        }).then((res) => {
          console.log(res.data.data)
          window.location.href = res.data.data
        })
    },
    // getroute(str,data){
    //     let arr1 = str.split('&')
    //     let arr2 = arr1.map(item=>{
    //         return item.split('=')
    //     }).flat()
    //     let i = arr2.findIndex(item => {
    //         return item == data
    //     })
    //     if(i == -1){
    //         return ''
    //     }else{
    //         return arr2[i+1]
    //     }
    // }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
  .home {
    width: 100%;
    padding: 16px;
    .errmess {
      box-sizing: border-box;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        margin-bottom: 50px;
      }
      .inputbox {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        div {
          width: 70%;
          border: #ccc 1px solid;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          padding-left: 6px;
          align-items: center;
          height: 55px;
          input {
            &::placeholder {
              color: #ccc;
            }
          }
        }
        .phonebutton {
          width: 60px;
          height: 40px;
          background-color: rgb(60, 175, 228);
          padding: 4px 8px;
          color: #fff;
          border-radius: 4px;
          margin-left: 16px;
        }
      }
    }
    .main {
      width: 100%;
      .balance {
        height: 130px;
        padding-left: 24px;
        padding-top: 20px;
        margin-bottom: 20px;
        background: linear-gradient(131deg, #FFE5E2 0%, #FFF6F6 100%);
        border-radius: 16px;
        .header {
          display: flex;
          width: 100%;
          align-items: center;
          margin-bottom: 5px;
          justify-content: space-between;
          .userinfo {
            margin-right: 40px;
            display: flex;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
            .mess {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              text-align: left;
              margin-left: 10px;
            }
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #333333;
            margin-bottom: 5px;
            margin-right: 20px;
          }
        }
        .moneybox {
          width: 100%;
          .balancemoney {
            font-size: 30px;
            height: 42px;
            line-height: 42px;
            margin: 0 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
          }
        }
      }
      .paylist {
        width: 100%;
        li {
          height: 75px;
          background: #F8F8F8;
          border-radius: 16px;
          padding: 0 24px;
          margin-bottom: 10px;
          position: relative;
          .left {
            height: 75px;
            img {
              margin-right: 10px;
            }
            .center {
              p {
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 22px;
              }
              span {
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #F1533E;
                line-height: 17px;
              }
            }
          }
          .rightmoney {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
          }
        }
        .on {
          background: #FFD9D5;
        }
        .sc {
          position: absolute;
          right: 0;
          top: 0;
          width: 68px;
          height: 22px;
          line-height: 22px;
          background: linear-gradient(83deg, #FFA4BE 0%, #FF7D6C 100%);
          border-radius: 0px 11px 0px 11px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
      .paybtn {
        position: fixed;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        height: 48px;
        background: #F15541;
        border-radius: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
    .content {
      height: 260px;
      padding: 0 16px;
      width: 100%;
      .header {
        border-bottom: 1px solid #eee;
        height: 50px;
        p {
          font-size: 22px;
          font-weight: 500;
        }
        strong {
          font-size: 22px;
          font-weight: 500;
        }
      }
      .center {
        .price{
          color: #F15541;
          font-size: 24px;
          font-weight: 500;
          padding: 25px 0;
          text-align: center;
        }
        .way {
          margin-bottom: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          span {
            margin-left: 10px;
          }
        }
      }
      .paybtn {
        width: 100%;
        height: 48px;
        background: #F15541;
        border-radius: 15px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
</style>
